<template>
  <div>
    <div>
      <div class="m-2">
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            />
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-b-modal="'modal-add'"
                  variant="primary"
                >
                  <span class="text-nowrap">Add Number</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            v-for="(v, i) in supportNumbers"
            :key="i"
          >
            <b-card
              cols="4"
            >
              <hr>
              <b-card-text>
                <strong>Name:</strong> {{ v.name }}
              </b-card-text>
              <b-card-text>
                <strong>Mobile Number:</strong> {{ v.phone }}
              </b-card-text>
              <b-card-text>
                <strong>Title:</strong> {{ v.title }}
              </b-card-text>
              <b-card-text>
                <strong>Country Logo:</strong> <br>
                <img
                  class="mt-2"
                  :src="v.logo"
                  :alt="v.title"
                  width="100"
                >
              </b-card-text>
              <hr>
              <template>
                <b-button
                  v-b-modal="'modal-edit'"
                  class="m-1"
                  variant="primary"
                  @click="getNumber(v.id)"
                >
                  <feather-icon icon="EditIcon" />
                  <span> Edit </span>
                </b-button>
                <b-button @click="deleteContact(v.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-button>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      v-if="show"
      id="modal-edit"
      centered
      title="Edit Contact"
      hide-footer
    >
      <b-form
        ref="myForm"
        class="p-2"
        @submit.enter.prevent=""
      >
        <b-form-group
          label="Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.name"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.phone"
            placeholder="Phone"
          />
        </b-form-group>
        <b-form-group
          label="Title"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="editData.title"
            placeholder="Title"
          />
        </b-form-group>
        <b-form-group
          id="fileInput"
        >
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            placeholder="Drag&amp;drop or select Image"
            @change="onChangeEdit"
          />
          <div
            v-if="preview"
            class="mt-2"
          >
            <img
              :src="preview"
              class="img-fluid"
              width="100"
            >
          </div>
        </b-form-group>
        <select-language :selected-language="editData" />
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="updateCat(editData.id)"
          >
            save
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      v-if="show"
      id="modal-add"
      centered
      title="Add Contact"
      hide-footer
    >
      <b-form
        ref="myForm"
        class="p-2"
        @submit.enter.prevent=""
      >
        <b-form-group
          label="Name"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.name"
            placeholder="Name"
          />
        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.phone"
            placeholder="Phone"
          />
        </b-form-group>
        <b-form-group
          label="Other Phone"
          label-for="cat_name"
        >
          <b-form-input
            id="cat_name"
            v-model="addData.title"
            placeholder="Other Phone"
          />
        </b-form-group>
        <b-form-group
          id="fileInput"
        >
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            placeholder="Drag&amp;drop or select Image"
            @change="onChange"
          />
          <div
            v-if="preview"
            class="mt-2"
          >
            <img
              :src="preview"
              class="img-fluid"
              width="100"
            >
          </div>
        </b-form-group>
        <select-language :selected-language="addData" />
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addNumber()"
          >
            save
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink, BImg, BFormFile, BCardText, BMediaBody, BMediaAside,
  BDropdown, BDropdownItem, BPagination, BFormGroup, BFormInvalidFeedback, BFormTextarea, BFormSelect, VBModal, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import handleFormData from '@/common/compositions/handleFormData'
import selectLanguage from '@/common/components/SelectLanguage.vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BMediaAside,
    selectLanguage,
    BCardText,
    BMediaBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BForm,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      editData: {},
      show: true,
      supportNumbers: [],
      addData: {
        title: '',
        name: '',
        logo: '',
        phone: '',
        language_id: '',
      },
      preview: null,
      image: null,
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {
    this.getsupportNumbers()
  },
  methods: {
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.addData.logo = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    onChangeEdit(e) {
      // eslint-disable-next-line prefer-destructuring
      this.editData.logo = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
    async getsupportNumbers() {
      await axios.get('get/call_center').then(res => {
        this.supportNumbers = res.data.success.data
      })
    },
    getNumber(id) {
      axios.get(`get/call_center/${id}`).then(res => {
        this.editData = res.data.success
        this.preview = res.data.success.logo
      })
    },
    updateCat(id) {
      this.setFormData(this.editData)
      axios.post(`edit/call_center/${id}`, this.formData).then(res => {
        if (res.status === 200) {
          this.getsupportNumbers()
          this.$bvModal.hide('modal-edit')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Edited Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.editData = {}
        }
      })
    },
    deleteContact(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios.delete(`delete/call_center/${id}`).then(res => {
            if (res.status === 200) {
              this.getsupportNumbers()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted Succesfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
            }
          })
        }
      })
    },
    addNumber() {
      this.setFormData(this.addData)
      axios.post('add/call_center', this.formData).then(res => {
        if (res.status === 200) {
          this.getsupportNumbers()
          this.preview = null
          this.addData = {}
          this.$bvModal.hide('modal-add')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Added Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
